import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { db, GeneralSettings as GeneralSettingsDexie } from "@/lib/indexeddb";
import clone from "lodash/cloneDeep";
import { GeneralClient } from "@/lib/clients";
import { News } from "@/lib/models/general";

const generalClient = new GeneralClient();

// Stateの型定義
export interface GeneralState {
  readPopupList: string[];
  readLatestNews: News;
  latestNews: News;
  agentPopupDisplayedNovelIds: string[];
  agentPopupDisplayFlagNovelId: string | null;
  isVisibleNolaNovelCompanyRecommendPopup: boolean;
  hasDisplayedWorkStatusOnboardingPopup: boolean;
  hasDisplayedWorkStatusHighlightPopup: boolean;
  hasDisplayedPenNameSavePopup: boolean;
}

type Getters = {
  readPopupList(state: GeneralState): string[];
  readLatestNews(state: GeneralState): News;
  latestNews(state: GeneralState): News;
  isAgentPopupDisplayedForNovel(state: GeneralState): (novelId: string) => boolean;
  agentPopupDisplayFlagNovelId(state: GeneralState): string | null;
  isVisibleNolaNovelCompanyRecommendPopup(state: GeneralState): boolean;
  hasDisplayedWorkStatusOnboardingPopup(state: GeneralState): boolean;
  hasDisplayedWorkStatusHighlightPopup(state: GeneralState): boolean;
  hasDisplayedPenNameSavePopup(state: GeneralState): boolean;
};

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<GeneralState, {}>) => any;
  readPopup: (this: Store<{}>, injectee: ActionContext<GeneralState, {}>, payload: string) => any;
  createSampleData: (this: Store<{}>, injectee: ActionContext<GeneralState, {}>) => any;
  readNews: (this: Store<{}>, injectee: ActionContext<GeneralState, {}>, payload: News) => Promise<void>;
  addDisplayedAgentPopupForNovel: (
    this: Store<{}>,
    injectee: ActionContext<GeneralState, {}>,
    payload: string
  ) => Promise<void>;
};

const mutations: MutationTree<GeneralState> = {
  setReadPopupList(state, payload: string[]) {
    return (state.readPopupList = payload);
  },
  setReadLatestNews(state, payload: News) {
    return (state.readLatestNews = payload);
  },
  setLatestNews(state, payload: News) {
    return (state.latestNews = payload);
  },
  setAgentPopupDisplayedNovelIds(state, payload: string[]) {
    return (state.agentPopupDisplayedNovelIds = payload);
  },
  setAgentPopupDisplayFlagNovelId(state, payload: string) {
    return (state.agentPopupDisplayFlagNovelId = payload);
  },
  setVisibleNolaNovelCompanyRecommendPopup(state, payload: boolean) {
    return (state.isVisibleNolaNovelCompanyRecommendPopup = payload);
  },
  setDisplayedWorkStatusOnboardingPopup(state, payload: boolean) {
    return (state.hasDisplayedWorkStatusOnboardingPopup = payload);
  },
  setDisplayedWorkStatusHighlightPopup(state, payload: boolean) {
    return (state.hasDisplayedWorkStatusHighlightPopup = payload);
  },
  setDisplayedPenNameSavePopup(state, payload: boolean) {
    return (state.hasDisplayedPenNameSavePopup = payload);
  },
};

const actions: ActionTree<GeneralState, {}> & Actions = {
  async initialize({ commit }) {
    const readPopupListInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("readPopupList")
    );
    if (readPopupListInIndexedDB) {
      commit("setReadPopupList", readPopupListInIndexedDB.value);
    }

    const readLatestNewsInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("readLatestNews")
    );
    if (readLatestNewsInIndexedDB) {
      commit("setReadLatestNews", readLatestNewsInIndexedDB.value);
    }

    const agentPopupDisplayedNovelIdsInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("agentPopupDisplayedNovelIds")
    );
    if (agentPopupDisplayedNovelIdsInIndexedDB) {
      commit("setAgentPopupDisplayedNovelIds", agentPopupDisplayedNovelIdsInIndexedDB.value);
    }

    const isVisibleNolaNovelCompanyRecommendPopupInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("isVisibleNolaNovelCompanyRecommendPopup")
    );
    if (isVisibleNolaNovelCompanyRecommendPopupInIndexedDB) {
      commit("setVisibleNolaNovelCompanyRecommendPopup", isVisibleNolaNovelCompanyRecommendPopupInIndexedDB.value);
    }

    const hasDisplayedWorkStatusOnboardingPopupInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("hasDisplayedWorkStatusOnboardingPopup")
    );
    if (hasDisplayedWorkStatusOnboardingPopupInIndexedDB) {
      commit("setDisplayedWorkStatusOnboardingPopup", hasDisplayedWorkStatusOnboardingPopupInIndexedDB.value);
    }

    const hasDisplayedWorkStatusHighlightPopupInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("hasDisplayedWorkStatusHighlightPopup")
    );
    if (hasDisplayedWorkStatusHighlightPopupInIndexedDB) {
      commit("setDisplayedWorkStatusHighlightPopup", hasDisplayedWorkStatusHighlightPopupInIndexedDB.value);
    }

    const hasDisplayedPenNameSavePopupInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("hasDisplayedPenNameSavePopup")
    );
    if (hasDisplayedPenNameSavePopupInIndexedDB) {
      commit("setDisplayedPenNameSavePopup", hasDisplayedPenNameSavePopupInIndexedDB.value);
    }
  },
  async readPopup({ commit, state }, payload) {
    const { readPopupList } = state;
    const cloneReadPopupList = clone(readPopupList);
    cloneReadPopupList.push(payload);

    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(cloneReadPopupList), "readPopupList");
    });

    commit("setReadPopupList", cloneReadPopupList);
  },
  async createSampleData() {
    await generalClient.createSampleData();
  },
  async readNews({ commit }, payload) {
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(payload), "readLatestNews");
    });

    commit("setReadLatestNews", payload);
  },
  async addDisplayedAgentPopupForNovel({ commit, state }, novelId) {
    const agentPopupDisplayedNovelIds = state.agentPopupDisplayedNovelIds || [];

    if (!agentPopupDisplayedNovelIds.includes(novelId)) {
      const updatedNovelIds = [...agentPopupDisplayedNovelIds, novelId];

      await db.transaction("readwrite", db.generalSettings, async () => {
        await db.generalSettings.put({ value: updatedNovelIds }, "agentPopupDisplayedNovelIds");
      });

      commit("setAgentPopupDisplayedNovelIds", updatedNovelIds);
    }
  },
  async setVisibleNolaNovelCompanyRecommendPopup({ commit }, isVisible) {
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put({ value: isVisible }, "isVisibleNolaNovelCompanyRecommendPopup");
    });

    commit("setVisibleNolaNovelCompanyRecommendPopup", isVisible);
  },
  async setDisplayedWorkStatusOnboardingPopup({ commit }, hasDisplayed) {
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put({ value: hasDisplayed }, "hasDisplayedWorkStatusOnboardingPopup");
    });

    commit("setDisplayedWorkStatusOnboardingPopup", hasDisplayed);
  },
  async setDisplayedWorkStatusHighlightPopup({ commit }, hasDisplayed) {
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put({ value: hasDisplayed }, "hasDisplayedWorkStatusHighlightPopup");
    });

    commit("setDisplayedWorkStatusHighlightPopup", hasDisplayed);
  },
  async setDisplayedPenNameSavePopup({ commit }, hasDisplayed) {
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put({ value: hasDisplayed }, "hasDisplayedPenNameSavePopup");
    });

    commit("setDisplayedPenNameSavePopup", hasDisplayed);
  },
};

const getters: GetterTree<GeneralState, {}> & Getters = {
  readPopupList: (state) => state.readPopupList,
  readLatestNews: (state) => state.readLatestNews,
  latestNews: (state) => state.latestNews,
  isAgentPopupDisplayedForNovel: (state) => (novelId: string) => {
    const agentPopupDisplayedNovelIds = state.agentPopupDisplayedNovelIds || [];
    return agentPopupDisplayedNovelIds.includes(novelId);
  },
  agentPopupDisplayFlagNovelId: (state) => state.agentPopupDisplayFlagNovelId,
  isVisibleNolaNovelCompanyRecommendPopup: (state) => state.isVisibleNolaNovelCompanyRecommendPopup,
  hasDisplayedWorkStatusOnboardingPopup: (state) => state.hasDisplayedWorkStatusOnboardingPopup,
  hasDisplayedWorkStatusHighlightPopup: (state) => state.hasDisplayedWorkStatusHighlightPopup,
  hasDisplayedPenNameSavePopup: (state) => state.hasDisplayedPenNameSavePopup,
};

export default {
  namespaced: true,
  state: {
    readPopupList: [],
    readLatestNews: null,
    latestNews: null,
    agentPopupDisplayedNovelIds: [],
    agentPopupDisplayFlagNovelId: null,
    isVisibleNolaNovelCompanyRecommendPopup: true,
    hasDisplayedWorkStatusOnboardingPopup: false,
    hasDisplayedWorkStatusHighlightPopup: false,
    hasDisplayedPenNameSavePopup: false,
  },
  getters,
  actions,
  mutations,
};
