import Vue from "vue";
import { Storage } from "aws-amplify";
import JSZip from "jszip";
import format from "date-fns/format";

/** プリセット画像一覧を取得します。 */
export async function listPublicImages(marker = null) {
  const res = (await Storage.list("shared/images/characters/", {
    level: "public",
  })) as [];
  return {
    nextMarker: null,
    contents: res,
  };
}

export async function downloadPublicImage(key: string) {
  const result = await Storage.get(key, {
    level: "public",
  });
  return result;
}

export async function saveBinary(key: string, binary: Buffer | Blob) {
  const result = await Storage.vault.put(key, binary);
  return result;
}

export async function loadWithCompress(key: string) {
  const zip = new JSZip();

  try {
    const data = (await Storage.vault.get(key, {
      download: true,
      expires: 0,
    })) as any;

    const lastModified = format(data.LastModified, "YYYY/MM/DD HH:mm:ss");

    await zip.loadAsync(data.Body);
    const zipFile = zip.file("content.txt");
    if (zipFile) {
      const content = await zipFile.async("string");
      return {
        content,
        lastModified,
      };
    }

    return {
      content: "",
      lastModified: "",
    };
  } catch (e) {
    if (e instanceof Error) {
      /** NOTE: S3にファイルが存在しない時は、エラー通知は出さずに空のデータを返す */
      if (e.name === "NoSuchKey") {
        return {
          content: "",
          lastModified: "",
        };
      }
    }

    Vue.notify({
      group: "exception",
      title: "原稿の取得に失敗しました",
      text: `ネットワーク接続状況を確認してください。\n${e}`,
      type: "error",
      duration: -1,
    });

    return {
      content: "",
      lastModified: "",
    };
  }
}

export async function saveWithCompress(key: string, content: string) {
  const zip = new JSZip();
  zip.file("content.txt", content);
  const binary = await zip.generateAsync({ type: "blob" });
  const result = await Storage.vault.put(key, binary);
  return result;
}

export function getNovelKey(novelId: string, key: string) {
  return `novels/${novelId}/manuscripts/${key}.zip`;
}

export function getCharacterKey(novelId: string, character: any) {
  const { characterId, image } = character;
  if (!image) {
    return null;
  }
  if (image.startsWith("file:")) {
    return `file:novels/${novelId}/characters/${characterId}.jpg`;
  }
  return image;
}

export function getWorldViewlKey(novelId: string, worldView: any) {
  const { worldViewId, image } = worldView;
  if (!image) {
    return null;
  }
  if (image.startsWith("file:")) {
    return `file:novels/${novelId}/worldviews/${worldViewId}.jpg`;
  }
  return image;
}

/**
 * S3から画像、音声、動画、PDF、実行ファイルなどの非テキストファイルのバイナリデータを取得する関数。
 * オブジェクトが存在する場合はバイナリ形式のデータ（Buffer）を返します。
 * 存在しない場合は null を返します。
 */
export async function fetchS3BinaryData(s3Key: string): Promise<Buffer | null> {
  try {
    // Storage.vault.getでS3の認証済みユーザー専用のオブジェクトにアクセスし、データを取得
    const s3Object = (await Storage.vault.get(s3Key, { download: true, expires: 0 })) as any;
    return s3Object.Body;
  } catch (error: any) {
    if (error.name === "NoSuchKey" || (error.response && error.response.status === 404)) {
      console.log("S3 Object not found.");
      return null; // オブジェクトが存在しない場合
    }
    console.error("Error fetching S3 object:", error);
    throw error; // その他のエラー
  }
}
