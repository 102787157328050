import { ManuscriptClient, DisplayOrderClient } from "@/lib/clients";
import { CountType, Manuscript, SearchTextIndex, ShowFlags, Theme } from "@/lib/models/manuscript";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { loadWithCompress, saveWithCompress, getNovelKey } from "@/lib/s3";
import { SharedManuscriptStorage } from "@/lib/storages";
import { db, GeneralSettings as GeneralSettingsDexie } from "@/lib/indexeddb";
import { v4 as uuidv4 } from "uuid";
import { UpdateOrder } from "@/lib/models/sortOrder";
import { SharedLinkKind, ManuscriptFolder, CreateManuscriptFolder, UpdateManuscriptFolder } from "@/lib/models";

const manuscriptClient = new ManuscriptClient();
const displayOrderClient = new DisplayOrderClient();

// Stateの型定義
export interface ManuscriptState {
  manuscriptList: Manuscript[];
  createdManuscriptKey: string;
  theme: Theme;
  countType: CountType;
  isShowPreviewOnManuscriptEditor: boolean;
  showFlags: ShowFlags;
  scrollRatio: number;
  saveTimeout: boolean;
  // eslint-disable-next-line no-undef
  saveTimeoutId: NodeJS.Timeout | null;
  autoSave: boolean;
  autoIndent: boolean;
  isFirstTimeAutoIndent: boolean;
  isShowingAutoIndentFirstTimeMessage: boolean;
  isShowEditorController: boolean;
  enabledSearchReplace: boolean;
  enabledSearch: boolean;
  enabledReplace: boolean;
  enabledReplaceSingle: boolean;
  enabledReplaceAll: boolean;
  searchText: string;
  replaceText: string;
  searchTargetIndex: number;
  searchTotalCount: number;
  searchTextIndexList: SearchTextIndex[];
  baseWordCount: number;
  restoredContent: string | null;
  progressPercentage: boolean;
  manuscriptFolderList: ManuscriptFolder[];
  isExportCompleted: boolean;
  isExportCancelled: boolean;
}

type Getters = {
  manuscript(state: ManuscriptState): (id: string) => Manuscript | undefined;
  manuscriptList(state: ManuscriptState): Manuscript[];
  createdManuscriptKey(state: ManuscriptState): string;
  theme(state: ManuscriptState): Theme;
  countType(state: ManuscriptState): CountType;
  isShowPreviewOnManuscriptEditor(state: ManuscriptState): boolean;
  showFlags(state: ManuscriptState): ShowFlags;
  scrollRatio(state: ManuscriptState): number;
  autoSave(state: ManuscriptState): boolean;
  autoIndent(state: ManuscriptState): boolean;
  isShowEditorController(state: ManuscriptState): boolean;
  isShowingAutoIndentFirstTimeMessage(state: ManuscriptState): boolean;
  enabledSearchReplace(state: ManuscriptState): boolean;
  enabledSearch(state: ManuscriptState): boolean;
  enabledReplace(state: ManuscriptState): boolean;
  enabledReplaceSingle(state: ManuscriptState): boolean;
  enabledReplaceAll(state: ManuscriptState): boolean;
  searchText(state: ManuscriptState): string;
  replaceText(state: ManuscriptState): string;
  searchTargetIndex(state: ManuscriptState): number;
  searchTotalCount(state: ManuscriptState): number;
  searchTextIndexList(state: ManuscriptState): SearchTextIndex[];
  baseWordCount(state: ManuscriptState): number;
  restoredContent(state: ManuscriptState): string | null;
  progressPercentage(state: ManuscriptState): boolean;
  manuscriptFolder(state: ManuscriptState): (folderId: string) => ManuscriptFolder | undefined;
  manuscriptFolderList(state: ManuscriptState): ManuscriptFolder[];
  foldersBelongManuscript(state: ManuscriptState): (manuscriptKey: string) => ManuscriptFolder[];
  isExportCompleted(state: ManuscriptState): boolean;
  isExportCancelled(state: ManuscriptState): boolean;
};

type Actions = {
  initialize: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptState, {}>,
    payload: InitializeManuscriptPayload
  ) => any;
  createManuscript: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptState, {}>,
    payload: CreateManuscriptPayload
  ) => any;
  updateManuscript: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptState, {}>,
    payload: UpdateManuscriptPayload
  ) => any;
  deleteManuscript: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptState, {}>,
    payload: DeleteManuscriptPayload
  ) => any;
  initializeCreatedManuscriptKey: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  selectTheme: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>, payload: Theme) => any;
  selectCountType: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>, payload: CountType) => any;
  switchPreviewOnManuscriptEditor: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  updateShowFlags: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>, payload: ShowFlags) => any;
  updateScrollRatio: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>, payload: number) => any;
  startSaveTimeout: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  stopSaveTimeout: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  toggleAutoSave: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  toggleShowEditorController: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  showAutoIndentMessage: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  closeAutoIndentMessage: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  toggleAutoIndent: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  toggleEnabledSearchReplace: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  toggleEnabledSearch: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  toggleEnabledReplace: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  toggleEnabledReplaceSingle: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  toggleEnabledReplaceAll: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  updateSearchText: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>, payload: string) => any;
  updateReplaceText: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>, payload: string) => any;
  updateSearchTargetIndex: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>, payload: number) => any;
  updateSearchTotalCount: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>, payload: number) => any;
  updateSearchTextIndexList: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptState, {}>,
    payload: SearchTextIndex[]
  ) => any;
  incrementWritingCount: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptState, {}>,
    payload: IncrementWritingCountPayload
  ) => any;
  restoreVersion: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptState, {}>,
    payload: RestoreVersionPayload
  ) => Promise<boolean>;
  fetchVersionContent: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptState, {}>,
    payload: RestoreVersionPayload
  ) => any;
  initializeRestoredContent: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  updateOrder: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>, payload: UpdateOrder) => Promise<void>;
  toggleProgressPercentage: (this: Store<{}>, injectee: ActionContext<ManuscriptState, {}>) => any;
  createFolder: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptState, {}>,
    payload: CreateManuscriptFolder
  ) => Promise<void>;
  updateFolder: (
    this: Store<{}>,
    injectee: ActionContext<ManuscriptState, {}>,
    payload: UpdateManuscriptFolder
  ) => Promise<void>;
};

interface InitializeManuscriptPayload {
  novelId: string;
  callback: () => void;
}

interface CreateManuscriptPayload {
  novelId: string;
  title: string;
  content?: string;
  manuscriptFolderId?: string;
  callback: () => void;
}

type CreateMultipleManuscriptPayload = {
  params: Omit<CreateManuscriptPayload, "callback">[];
  callback: () => void;
};

interface UpdateManuscriptPayload {
  novelId: string;
  key: string;
  title: string;
  content: string;
  callback: () => void;
}

interface DeleteManuscriptPayload {
  novelId: string;
  key: string;
  callback?: () => void;
}

interface IncrementWritingCountPayload {
  novelId: string;
  value: number;
}

interface RestoreVersionPayload {
  novelId: string;
  manuscriptKey: string;
  versionId: string;
}

const mutations: MutationTree<ManuscriptState> = {
  setManuscriptList(state, payload) {
    return (state.manuscriptList = payload);
  },
  pushManuscript(state, payload) {
    state.manuscriptList.push(payload);
    return state.manuscriptList;
  },
  updateManuscript(state, payload: Manuscript) {
    return (state.manuscriptList = state.manuscriptList.map((manuscript) => {
      if (manuscript.key === payload.key) {
        return payload;
      }
      return manuscript;
    }));
  },
  popManuscript(state, payload) {
    const manuscriptList = state.manuscriptList.filter((manuscript) => manuscript.key !== payload);
    return (state.manuscriptList = manuscriptList);
  },
  setCreatedManuscriptKey(state, payload) {
    return (state.createdManuscriptKey = payload);
  },
  setTheme(state, payload: Theme) {
    return (state.theme = payload);
  },
  setCountType(state, payload: CountType) {
    return (state.countType = payload);
  },
  switchIsShowPreviewOnManuscriptEditor(state) {
    return (state.isShowPreviewOnManuscriptEditor = !state.isShowPreviewOnManuscriptEditor);
  },
  setShowFlags(state, payload: ShowFlags) {
    return (state.showFlags = payload);
  },
  setScrollRatio(state, payload) {
    return (state.scrollRatio = payload);
  },
  setSaveTimeout(state, payload) {
    return (state.saveTimeout = payload);
  },
  setSaveTimeoutId(state, payload) {
    return (state.saveTimeoutId = payload);
  },
  clearSaveTimeout(state) {
    if (state.saveTimeoutId) {
      clearTimeout(state.saveTimeoutId);
    }
  },
  setAutoSave(state, payload) {
    return (state.autoSave = payload);
  },
  setAutoIndent(state, payload) {
    return (state.autoIndent = payload);
  },
  setFirstTimeAutoIndent(state, payload) {
    return (state.isFirstTimeAutoIndent = payload);
  },
  setShowingAutoIndentFirstTimeMessage(state, payload) {
    return (state.isShowingAutoIndentFirstTimeMessage = payload);
  },
  setShowEditorController(state, payload) {
    return (state.isShowEditorController = payload);
  },
  setEnabledSearchReplace(state, payload: boolean) {
    return (state.enabledSearchReplace = payload);
  },
  setEnabledSearch(state, payload: boolean) {
    return (state.enabledSearch = payload);
  },
  setEnabledReplace(state, payload: boolean) {
    return (state.enabledReplace = payload);
  },
  setEnabledReplaceSingle(state, payload: boolean) {
    return (state.enabledReplaceSingle = payload);
  },
  setEnabledReplaceAll(state, payload: boolean) {
    return (state.enabledReplaceAll = payload);
  },
  setSearchText(state, payload: string) {
    return (state.searchText = payload);
  },
  setReplaceText(state, payload: string) {
    return (state.replaceText = payload);
  },
  setSearchTargetIndex(state, payload: number) {
    return (state.searchTargetIndex = payload);
  },
  setSearchTotalCount(state, payload: number) {
    return (state.searchTotalCount = payload);
  },
  setSearchTextIndexList(state, payload: SearchTextIndex[]) {
    return (state.searchTextIndexList = payload);
  },
  setBaseWordCount(state, payload: number) {
    return (state.baseWordCount = payload);
  },
  setRestoredContent(state, payload: string | null) {
    return (state.restoredContent = payload);
  },
  setProgressPercentage(state, payload) {
    return (state.progressPercentage = payload);
  },
  setManuscriptFolderList(state, payload) {
    return (state.manuscriptFolderList = payload);
  },
  pushManuscriptFolder(state, payload) {
    state.manuscriptFolderList.push(payload);
    return state.manuscriptFolderList;
  },
  setExportCompleted(state, payload) {
    state.isExportCompleted = payload;
  },
  setExportCancelled(state, payload) {
    state.isExportCancelled = payload;
  },
};

const actions: ActionTree<ManuscriptState, {}> & Actions = {
  async initialize({ commit }, payload: InitializeManuscriptPayload) {
    /** 初期化 */
    commit("setManuscriptList", []);

    const { novelId, callback } = payload;

    const manuscriptFolderList = await manuscriptClient.fetchAllManuscriptFolder(novelId);
    commit("setManuscriptFolderList", manuscriptFolderList);

    const manuscriptList = await manuscriptClient.fetchAllManuscript(novelId);

    const promiseItems = manuscriptList.map(async (manuscript) => {
      const { key } = manuscript;
      if (!key) {
        return { ...manuscript, novelId, content: "", lastModified: "" };
      }

      // 原稿本文を取得
      const { content, lastModified } = await loadWithCompress(getNovelKey(novelId, key));

      // 原稿のバージョンを取得
      const versions = await manuscriptClient.fetchManuscriptVersions(novelId, key);

      return { ...manuscript, novelId, content, lastModified, versions };
    });
    const manuscripts = await Promise.all(promiseItems);

    const displayOrder = await displayOrderClient.fetchDisplayOrder({ novelId, kind: SharedLinkKind.MANUSCRIPT });

    if (displayOrder.order.length === 0) {
      commit("setManuscriptList", manuscripts);
      if (callback) callback();
      return;
    }

    // displayOrderに従っているmanuscriptsをソート
    const orderedManuscripts = manuscripts
      .filter((m) => m.key !== undefined && displayOrder.order.includes(m.key))
      .sort((a, b) => displayOrder.order.indexOf(a.key!) - displayOrder.order.indexOf(b.key!));

    // displayOrderに存在しないキーを持つmanuscriptsを特定
    const unorderedManuscripts = manuscripts.filter((m) => m.key !== undefined && !displayOrder.order.includes(m.key));

    // 両方の配列を結合してcommit
    const sortedManuscripts = [...orderedManuscripts, ...unorderedManuscripts];
    commit("setManuscriptList", sortedManuscripts);

    if (callback) callback();
  },
  async createManuscript({ commit }, payload: CreateManuscriptPayload) {
    const { novelId, title, content = "", callback } = payload;

    const key = await manuscriptClient.createManuscript(payload);
    await saveWithCompress(getNovelKey(novelId, key), content);
    const { lastModified } = await loadWithCompress(getNovelKey(novelId, key));

    // 原稿のバージョンを取得
    const versions = await manuscriptClient.fetchManuscriptVersions(novelId, key);

    commit("pushManuscript", { key, title, novelId, content, lastModified, versions } as Manuscript);
    commit("setCreatedManuscriptKey", key);
    if (callback) callback();
  },
  async createMultipleManuscript({ commit }, payload: CreateMultipleManuscriptPayload) {
    const { params, callback } = payload;
    const payloads = params.map((item) => {
      const { novelId, title, content = "", manuscriptFolderId } = item;
      return { novelId, key: uuidv4(), title, content, manuscriptFolderId };
    });

    await manuscriptClient.createMultipleManuscript(payloads);

    const promiseArray = payloads.map(async (manuscript) => {
      const { novelId, key, title, content } = manuscript;

      await saveWithCompress(getNovelKey(novelId, key), content);
      const { lastModified } = await loadWithCompress(getNovelKey(novelId, key));

      // 原稿のバージョンを取得
      const versions = await manuscriptClient.fetchManuscriptVersions(novelId, key);

      commit("pushManuscript", { key, title, novelId, content, lastModified, versions } as Manuscript);
    });
    await Promise.all(promiseArray);

    if (callback) callback();
  },
  async updateManuscript({ commit }, payload: UpdateManuscriptPayload) {
    const { novelId, key, content, callback } = payload;

    const manuscriptList = await manuscriptClient.updateManuscript(payload);
    await saveWithCompress(getNovelKey(novelId, key), content);
    const { lastModified } = await loadWithCompress(getNovelKey(novelId, key));

    // 原稿のバージョンを取得
    const versions = await manuscriptClient.fetchManuscriptVersions(novelId, key);

    /** protectedの原稿も更新する */
    const sharedManuscriptStorage = new SharedManuscriptStorage();
    await sharedManuscriptStorage.uploadFile(novelId, key, content);

    const updatedManuscript = manuscriptList.find((manuscript) => manuscript.key === payload.key);

    if (updatedManuscript) {
      commit("updateManuscript", { ...updatedManuscript, content, lastModified, versions } as Manuscript);
      if (callback) callback();
    }
  },
  async deleteManuscript({ commit }, payload: DeleteManuscriptPayload) {
    const { key, callback } = payload;

    await manuscriptClient.deleteManuscript(payload);

    commit("popManuscript", key);
    if (callback) callback();
  },
  initializeCreatedManuscriptKey({ commit }) {
    commit("setCreatedManuscriptKey", "");
  },
  async selectTheme({ commit }, value) {
    commit("setTheme", value);
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(value), "theme");
    });
  },
  async selectCountType({ commit }, value) {
    commit("setCountType", value);
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(value), "countType");
    });
  },
  switchPreviewOnManuscriptEditor({ commit }) {
    commit("switchIsShowPreviewOnManuscriptEditor");
  },
  updateShowFlags({ commit }, value) {
    commit("setShowFlags", value);
  },
  updateScrollRatio({ commit }, value) {
    commit("setScrollRatio", value);
  },
  async initializeGeneralSettings({ commit }) {
    const themeInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("theme")
    );
    if (themeInIndexedDB) {
      commit("setTheme", themeInIndexedDB.value);
    }

    const countTypeInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("countType")
    );
    if (countTypeInIndexedDB) {
      commit("setCountType", countTypeInIndexedDB.value);
    }

    const autoSaveInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("autoSave")
    );
    if (autoSaveInIndexedDB) {
      commit("setAutoSave", autoSaveInIndexedDB.value);
    }

    const autoIndentInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("autoIndent")
    );
    if (autoIndentInIndexedDB) {
      commit("setAutoIndent", autoIndentInIndexedDB.value);
    }
    commit("setFirstTimeAutoIndent", !autoIndentInIndexedDB);

    const isShowEditorControllerInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("isShowEditorController")
    );
    if (isShowEditorControllerInIndexedDB) {
      commit("setShowEditorController", isShowEditorControllerInIndexedDB.value);
    }

    const progressPercentageInIndexedDB = await db.transaction(
      "readonly",
      db.generalSettings,
      async () => await db.generalSettings.get("progressPercentage")
    );
    if (progressPercentageInIndexedDB) {
      commit("setProgressPercentage", progressPercentageInIndexedDB.value);
    }
  },
  startSaveTimeout({ commit }) {
    commit("clearSaveTimeout");
    const saveTimeoutId = setTimeout(() => {
      commit("setSaveTimeout", true);
    }, 3000);
    commit("setSaveTimeoutId", saveTimeoutId);
  },
  stopSaveTimeout({ commit }) {
    commit("clearSaveTimeout");
    commit("setSaveTimeout", false);
    commit("setSaveTimeoutId", null);
  },
  async toggleAutoSave({ commit, state }) {
    const { autoSave } = state;
    commit("setAutoSave", !autoSave);
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(!autoSave), "autoSave");
    });
  },
  async toggleShowEditorController({ commit, state }) {
    const { isShowEditorController } = state;
    commit("setShowEditorController", !isShowEditorController);
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(!isShowEditorController), "isShowEditorController");
    });
  },
  async toggleAutoIndent({ commit, state }) {
    const { autoIndent } = state;
    commit("setAutoIndent", !autoIndent);
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(!autoIndent), "autoIndent");
    });
  },
  showAutoIndentMessage({ commit }) {
    commit("setShowingAutoIndentFirstTimeMessage", true);
  },
  async closeAutoIndentMessage({ commit }) {
    commit("setShowingAutoIndentFirstTimeMessage", false);
    commit("setFirstTimeAutoIndent", false);

    await db.transaction("readwrite", db.generalSettings, async () => {
      const record = await db.generalSettings.get("autoIndent");
      if (!record) {
        await db.generalSettings.put(new GeneralSettingsDexie(true), "autoIndent");
      }
    });
  },
  toggleEnabledSearchReplace({ commit, state }) {
    const { enabledSearchReplace } = state;
    commit("setEnabledSearchReplace", !enabledSearchReplace);
  },
  toggleEnabledSearch({ commit, state }) {
    const { enabledSearch } = state;
    commit("setEnabledSearch", !enabledSearch);
  },
  toggleEnabledReplace({ commit, state }) {
    const { enabledReplace } = state;
    commit("setEnabledReplace", !enabledReplace);
  },
  toggleEnabledReplaceSingle({ commit, state }) {
    const { enabledReplaceSingle } = state;
    commit("setEnabledReplaceSingle", !enabledReplaceSingle);
  },
  toggleEnabledReplaceAll({ commit, state }) {
    const { enabledReplaceAll } = state;
    commit("setEnabledReplaceAll", !enabledReplaceAll);
  },
  updateSearchText({ commit }, value) {
    commit("setSearchText", value);
  },
  updateReplaceText({ commit }, value) {
    commit("setReplaceText", value);
  },
  updateSearchTargetIndex({ commit }, value) {
    commit("setSearchTargetIndex", value);
  },
  updateSearchTotalCount({ commit }, value) {
    commit("setSearchTotalCount", value);
  },
  updateSearchTextIndexList({ commit }, value) {
    commit("setSearchTextIndexList", value);
  },
  async incrementWritingCount({ commit, state }, payload) {
    const { novelId, value } = payload;
    const { baseWordCount } = state;

    const recordWordCount = value - baseWordCount;
    if (recordWordCount > 0) await manuscriptClient.incrementWritingCount(novelId, recordWordCount);
    commit("setBaseWordCount", value);
  },
  async restoreVersion({ commit }, payload) {
    const { novelId, manuscriptKey, versionId } = payload;
    const content = await manuscriptClient.fetchManuscriptContent(novelId, manuscriptKey, versionId);

    if (typeof content !== "string") return false;

    commit("setRestoredContent", content);
    return true;
  },
  async fetchVersionContent(_, payload) {
    const { novelId, manuscriptKey, versionId } = payload;
    return await manuscriptClient.fetchManuscriptContent(novelId, manuscriptKey, versionId);
  },
  initializeRestoredContent({ commit }) {
    commit("setRestoredContent", null);
  },
  async updateOrder(_, payload) {
    await displayOrderClient.updateDisplayOrder({
      ...payload,
      kind: SharedLinkKind.MANUSCRIPT,
    });
  },
  async toggleProgressPercentage({ commit, state }) {
    const { progressPercentage } = state;
    commit("setProgressPercentage", !progressPercentage);
    await db.transaction("readwrite", db.generalSettings, async () => {
      await db.generalSettings.put(new GeneralSettingsDexie(!progressPercentage), "progressPercentage");
    });
  },
  async createFolder({ commit }, payload) {
    const folder = await manuscriptClient.createManuscriptFolder(payload);
    commit("pushManuscriptFolder", { ...folder, manuscriptKeys: [] });
  },
  async updateFolder({ commit }, payload) {
    const folders = await manuscriptClient.updateManuscriptFolder(payload);
    commit("setManuscriptFolderList", folders);
  },
};

const getters: GetterTree<ManuscriptState, {}> & Getters = {
  manuscript: (state) => (id: string) => state.manuscriptList.find((manuscript) => manuscript.key === id),
  manuscriptList: (state) => state.manuscriptList,
  createdManuscriptKey: (state) => state.createdManuscriptKey,
  theme: (state) => state.theme,
  countType: (state) => state.countType,
  isShowPreviewOnManuscriptEditor: (state) => state.isShowPreviewOnManuscriptEditor,
  showFlags: (state) => state.showFlags,
  scrollRatio: (state) => state.scrollRatio,
  saveTimeout: (state) => state.saveTimeout,
  autoSave: (state) => state.autoSave,
  autoIndent: (state) => state.autoIndent,
  isShowingAutoIndentFirstTimeMessage: (state) => state.isShowingAutoIndentFirstTimeMessage,
  isFirstTimeAutoIndent: (state) => state.isFirstTimeAutoIndent,
  isShowEditorController: (state) => state.isShowEditorController,
  enabledSearchReplace: (state) => state.enabledSearchReplace,
  enabledSearch: (state) => state.enabledSearch,
  enabledReplace: (state) => state.enabledReplace,
  enabledReplaceSingle: (state) => state.enabledReplaceSingle,
  enabledReplaceAll: (state) => state.enabledReplaceAll,
  searchText: (state) => state.searchText,
  replaceText: (state) => state.replaceText,
  searchTargetIndex: (state) => state.searchTargetIndex,
  searchTotalCount: (state) => state.searchTotalCount,
  searchTextIndexList: (state) => state.searchTextIndexList,
  baseWordCount: (state) => state.baseWordCount,
  restoredContent: (state) => state.restoredContent,
  progressPercentage: (state) => state.progressPercentage,
  manuscriptFolder: (state) => (folderId: string) =>
    state.manuscriptFolderList.find((x) => x.manuscriptFolderId === folderId),
  manuscriptFolderList: (state) => state.manuscriptFolderList,
  foldersBelongManuscript: (state) => (manuscriptKey: string) =>
    state.manuscriptFolderList.filter((x) => x.manuscriptKeys.includes(manuscriptKey)),
  isExportCompleted: (state) => state.isExportCompleted,
  isExportCancelled: (state) => state.isExportCancelled,
};

export default {
  namespaced: true,
  state: {
    manuscriptList: [],
    createdManuscriptKey: "",
    theme: "default",
    countType: "onlyCharacter",
    isShowPreviewOnManuscriptEditor: false,
    showFlags: {
      isShowPlots: true,
      isShowCharacters: true,
      isShowWorldViews: true,
      isShowMemo: false,
    },
    scrollRatio: 0,
    saveTimeout: false,
    saveTimeoutId: null,
    autoSave: false,
    autoIndent: true,
    isFirstTimeAutoIndent: false,
    isShowingAutoIndentFirstTimeMessage: false,
    isShowEditorController: true,
    enabledSearchReplace: false,
    enabledSearch: false,
    enabledReplace: false,
    enabledReplaceSingle: false,
    enabledReplaceAll: false,
    searchText: "",
    replaceText: "",
    searchTargetIndex: 0,
    searchTotalCount: 0,
    searchTextIndexList: [],
    baseWordCount: 0,
    restoredContent: null,
    progressPercentage: true,
    manuscriptFolderList: [],
    isExportCompleted: false,
    isExportCancelled: false,
  },
  getters,
  actions,
  mutations,
};
