
import Vue from "vue";

export default Vue.extend({
  props: {
    title: String,
    content: String,
    cancelable: {
      type: Boolean,
      default: true,
    },
    okButton: {
      type: String,
      default: "はい",
    },
  },
  methods: {
    cancel() {
      this.$close(false);
    },
    focusOut(e) {
      if (e.target.classList.contains("dialog-mask")) {
        //  this.$close(false);
      }
    },
  },
});
