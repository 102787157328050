import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";
import { db, TutorialSettings as TutorialSettingsDexie } from "@/lib/indexeddb";
import { Tutorial, TutorialState } from "@/lib/models/tutorial";

interface CreateTutorialPayload {
  tutorial: Tutorial;
  callback: () => void;
}

interface UpdateTutorialPayload {
  screen: string;
  category: string;
  step: string;
  callback: () => void;
}

type Getters = {
  tutorial(state: TutorialState): (screen: string, category: string) => Tutorial | undefined;
};

const getters: GetterTree<TutorialState, {}> & Getters = {
  tutorial: (state) => (screen: string, category: string) =>
    state.tutorials.find((tutorial) => tutorial.screen === screen && tutorial.category === category),
};

type Actions = {
  initialize: (this: Store<{}>, injectee: ActionContext<TutorialState, {}>) => Promise<void>;
  createTutorial: (this: Store<{}>, injectee: ActionContext<TutorialState, {}>, payload: CreateTutorialPayload) => void;
  updateTutorial: (this: Store<{}>, injectee: ActionContext<TutorialState, {}>, payload: UpdateTutorialPayload) => void;
};

const actions: ActionTree<TutorialState, {}> & Actions = {
  async initialize({ commit }) {
    const tutorials = await db.tutorialSettings.toArray();

    commit("setTutorials", tutorials);
  },
  async createTutorial({ commit }, payload: CreateTutorialPayload) {
    const { tutorial, callback } = payload;

    await db.transaction("readwrite", db.tutorialSettings, async () => {
      await db.tutorialSettings.put(
        new TutorialSettingsDexie(tutorial.screen, tutorial.category, tutorial.step),
        tutorial.screen + tutorial.category
      );
    });

    commit("addTutorial", tutorial);
    if (callback) callback();
  },

  async updateTutorial({ commit, state }, payload: UpdateTutorialPayload) {
    const { screen, category, step, callback } = payload;
    const tutorial = state.tutorials.find((tutorial) => tutorial.screen === screen && tutorial.category === category);

    if (tutorial) {
      const updatedTutorial = { ...tutorial, step };
      await db.transaction("readwrite", db.tutorialSettings, async () => {
        await db.tutorialSettings.put(
          new TutorialSettingsDexie(updatedTutorial.screen, updatedTutorial.category, updatedTutorial.step),
          updatedTutorial.screen + updatedTutorial.category
        );
      });

      commit("updateTutorial", updatedTutorial);
      if (callback) callback();
    }
  },
};

type Mutations<S = TutorialState> = {
  setTutorials(state: S, payload: Tutorial[]): void;
  addTutorial(state: S, payload: Tutorial): void;
  updateTutorial(state: S, payload: Tutorial): void;
};

const mutations: MutationTree<TutorialState> & Mutations = {
  setTutorials(state, payload) {
    state.tutorials = payload;
  },
  addTutorial(state, payload) {
    state.tutorials.push(payload);
  },

  updateTutorial(state, payload) {
    const index = state.tutorials.findIndex(
      (tutorial) => tutorial.screen === payload.screen && tutorial.category === payload.category
    );
    if (index !== -1) {
      state.tutorials.splice(index, 1, payload);
    }
  },
};

const state: TutorialState = {
  tutorials: [],
};

// storeのエクスポート
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
