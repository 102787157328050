import { db, ProofreadingSettings } from "@/lib/indexeddb";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";

export interface ProofreadingState {
  rules: any;
  enable: boolean;
  isConfirmed: boolean;
}

type Getters = {
  rules: (state: ProofreadingState) => any;
};

const getters: GetterTree<ProofreadingState, {}> & Getters = {
  rules: (state) => state.rules,
  enable: (state) => state.enable,
  isConfirmed: (state) => state.isConfirmed,
};

type Mutations = {
  setRules: (state: ProofreadingState, rules: any) => void;
  setEnable: (state: ProofreadingState, enable: boolean) => void;
  setConfirmed: (state: ProofreadingState, isConfirmed: boolean) => void;
};

const mutations: MutationTree<ProofreadingState> & Mutations = {
  setRules(state, payload: object) {
    return (state.rules = payload);
  },
  setEnable(state, payload: boolean) {
    return (state.enable = payload);
  },
  setConfirmed(state, payload: boolean) {
    return (state.isConfirmed = payload);
  },
};

type UpdateProofreadingPayload = {
  enable: boolean;
  rules: any;
  isConfirmed: string;
};

type Actions<S> = {
  update(
    this: Store<{}>,
    injectee: ActionContext<ProofreadingState, {}>,
    payload: UpdateProofreadingPayload
  ): Promise<void>;

  confirm(this: Store<{}>, injectee: ActionContext<ProofreadingState, {}>, payload: boolean): Promise<void>;
};

const actions: ActionTree<ProofreadingState, {}> & Actions<ProofreadingState> = {
  async initialize({ commit }) {
    const rules = await db.transaction(
      "readonly",
      db.proofreadingSettings,
      async () => await db.proofreadingSettings.get("default")
    );

    if (rules) {
      commit("setRules", rules.rules);
      commit("setEnable", rules.enable);
      commit("setConfirmed", rules.isConfirmed);
    } else {
      // 校正はデフォルトで有効になっている仕様のため enable = true で初期化
      commit("setEnable", true);
      commit("setRules", null);
      commit("setConfirmed", false);
    }
  },

  async update({ commit, state }, payload) {
    commit("setRules", payload.rules);
    commit("setEnable", payload.enable);

    await db.transaction("readwrite", db.proofreadingSettings, async () => {
      await db.proofreadingSettings.put(
        new ProofreadingSettings(payload.enable, payload.rules, state.isConfirmed),
        "default"
      );
    });
  },

  async confirm({ commit, state }, isConfirmed) {
    commit("setConfirmed", isConfirmed);

    await db.transaction("readwrite", db.proofreadingSettings, async () => {
      await db.proofreadingSettings.put(new ProofreadingSettings(state.enable, state.rules, isConfirmed), "default");
    });
  },
};

export default {
  namespaced: true,
  state: {
    rules: null,
    enable: true,
    isConfirmed: false,
  },
  getters,
  actions,
  mutations,
};
