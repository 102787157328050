import { GtmSupport, DataLayerObject } from "@gtm-support/vue2-gtm";
import { Auth } from "aws-amplify";
import { Store } from "vuex";
import { Novel, Plan, User } from "../models";
import { UserStatus, SubscribeStatus, LoginStatus, AgentStatus, AgentNovelCount } from "../userProperty";
import { isTwitterLogin } from "../twitterAuth";

/**
 * NOTE:
 * Google Analytics 4 のイベントトラッキングを行うクラス
 * vue2-gtmではGA4のイベントトラッキングに対応していないため、GA4向けにパラメータを設定している
 */
export class NolaAnalytics {
  constructor(private readonly gtm: GtmSupport, private readonly store: Store<any>) {}

  // ////////////////////////////////////////
  // Events
  // ////////////////////////////////////////

  // select_content イベント
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=13422939797024435244-AP&hl=ja&client_type=gtag#select_content
  private selectContent(eventDetails: { contentType: string; itemId: string; contentId?: string }) {
    this.trackEvent({
      event: "select_content",
      content_type: eventDetails.contentType,
      item_id: eventDetails.itemId,
      content_id: eventDetails.contentId,
    });
  }

  private viewItem(eventDetails: { contentType: string; itemId: string }) {
    this.trackEvent({
      event: "view_item",
      content_type: eventDetails.contentType,
      item_id: eventDetails.itemId,
      items: [
        {
          item_id: eventDetails.itemId,
        },
      ],
    });
  }

  private trackEvent(object: DataLayerObject) {
    const enabled = this.gtm.enabled();
    if (!enabled) {
      console.info("Google Tag Manager is not enabled.");
      return;
    }

    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(object);
    }
  }

  logButtonEvent(itemId: NolaItemId, contentId?: string) {
    this.selectContent({
      contentType: NolaContentType.Button,
      itemId,
      contentId,
    });
  }

  logViewItemEvent(itemId: NolaItemId, contentType: NolaContentType) {
    this.viewItem({
      contentType,
      itemId,
    });
  }

  // ////////////////////////////////////////
  // User Properties
  // ////////////////////////////////////////

  async setUserProperties() {
    const { gtm, store } = this;
    const { dataLayer } = window;

    // Amplify
    const credentials = (await Auth.currentCredentials()) as any;
    const isAuthenticated = credentials.authenticated;
    const isLogin = credentials && !credentials.code && (isTwitterLogin() || isAuthenticated);

    // Plan
    const plan = store.getters["userModule/plan"] as Plan;

    let userStatus: string;
    let subscribeStatus: string;
    switch (plan) {
      case Plan.free:
        userStatus = UserStatus.value.freeUser;
        subscribeStatus = SubscribeStatus.value.none;
        break;
      case Plan.monthly:
        userStatus = UserStatus.value.subscriptionUser;
        subscribeStatus = SubscribeStatus.value.subscribeMonth;
        break;
      case Plan.yearly:
        userStatus = UserStatus.value.subscriptionUser;
        subscribeStatus = SubscribeStatus.value.subscribeYear;
        break;
      case Plan.school:
        userStatus = UserStatus.value.schoolUser;
        subscribeStatus = SubscribeStatus.value.none;
        break;
      default:
        userStatus = UserStatus.value.unRegister;
        subscribeStatus = SubscribeStatus.value.none;
        break;
    }

    // GTMのユーザープロパティを設定
    if (gtm.enabled() && dataLayer) {
      // 初期値は未ログイン状態
      const userProperty = {
        user_id: null,
        [LoginStatus.key]: LoginStatus.value.loggedOut,
        [UserStatus.key]: UserStatus.value.unRegister,
        [SubscribeStatus.key]: SubscribeStatus.value.none,
      };

      if (isLogin) {
        // CognitoのIdentityIdを取得
        const sub = credentials.signInUserSession
          ? credentials.signInUserSession.idToken.payload.sub
          : credentials.data.IdentityId;

        // Userの取得
        const user = store.getters["userModule/user"] as User;

        // AgentStatus
        const { agentStartedAt } = user;
        const agentStatus = agentStartedAt ? AgentStatus.value.on : AgentStatus.value.off;

        // Novelの取得
        const novels = store.getters["novelModule/novels"] as Novel[];

        // AgentNovelCount
        const enabledAgentNovelCount = novels.filter((novel) => novel.agentStatus).length;
        let agentNovelCount = AgentNovelCount.value.zero;
        if (agentStatus === AgentStatus.value.on) {
          switch (true) {
            default:
            case enabledAgentNovelCount === 0:
              agentNovelCount = AgentNovelCount.value.zero;
              break;

            case enabledAgentNovelCount === 1:
              agentNovelCount = AgentNovelCount.value.one;
              break;

            case enabledAgentNovelCount === 2:
              agentNovelCount = AgentNovelCount.value.two;
              break;

            case enabledAgentNovelCount === 3:
              agentNovelCount = AgentNovelCount.value.three;
              break;

            case enabledAgentNovelCount === 4:
              agentNovelCount = AgentNovelCount.value.four;
              break;

            case enabledAgentNovelCount === 5:
              agentNovelCount = AgentNovelCount.value.five;
              break;

            case enabledAgentNovelCount >= 6 && enabledAgentNovelCount <= 10:
              agentNovelCount = AgentNovelCount.value.sixToTen;
              break;

            case enabledAgentNovelCount >= 11 && enabledAgentNovelCount <= 20:
              agentNovelCount = AgentNovelCount.value.elevenToTwenty;
              break;

            case enabledAgentNovelCount >= 21 && enabledAgentNovelCount <= 50:
              agentNovelCount = AgentNovelCount.value.twentyOneToFifty;
              break;

            case enabledAgentNovelCount >= 51 && enabledAgentNovelCount <= 99:
              agentNovelCount = AgentNovelCount.value.fiftyOneToNinetyNine;
              break;

            case enabledAgentNovelCount >= 100:
              agentNovelCount = AgentNovelCount.value.overOneHundred;
              break;
          }
        }

        userProperty.user_id = sub;
        userProperty[LoginStatus.key] = LoginStatus.value.loggedIn;
        userProperty[UserStatus.key] = userStatus;
        userProperty[SubscribeStatus.key] = subscribeStatus;
        userProperty[AgentStatus.key] = agentStatus;
        userProperty[AgentNovelCount.key] = agentNovelCount;
      }

      // ユーザープロパティをdataLayerに追加
      dataLayer.push(userProperty);

      // ユーザープロパティをGTMからGA4に送信するためのイベント
      dataLayer.push({ event: "user_property_push" });
    }
  }
}

export enum NolaContentType {
  Button = "button",
  Popup = "popup",
  Onboarding = "onboarding",
}

export enum NolaItemId {
  StartAgentFeature = "start_agent_feature",
  AgentCtaTop = "agent_cta_top",
  AgentCtaBottom = "agent_cta_bottom",
  AgentLink = "agent_link",
  AgentUnlink = "agent_unlink",
  CompanyCard = "company_card",
  ViewCompanyCardA = "view_company_card_a",
  ViewCompanyCardB = "view_company_card_b",
  CompanyCardViewDetailsButton = "company_card_view_details_button",
  PostEpisodeButton = "post_episode_button",
  UpdateNovelButton = "update_novel_button",
  NavigateToPublishedPageButton = "navigate_to_published_page_button",
  PublishToReadersButton = "publish_to_readers_button",
  NotificationItem = "notification_item",
  OnBoardingAdd = "onboarding_add",
  OnBoardingClose = "onboarding_close",
  ConfirmOverwriteEpisodeButton = "confirm_overwrite_episode_button",
  ConfirmOverwriteNovelButton = "confirm_overwrite_novel_button",
  SelectNewEpisodeButton = "select_new_episode_button",
  SelectForNewEpisodePostButton = "select_for_new_episode_post_button",
  SelectForOverwriteEpisodeButton = "select_for_overwrite_episode_button",
  SelectForNewNovelPostButton = "select_for_new_novel_post_button",
  SelectForOverwriteNovelButton = "select_for_overwrite_novel_button",
  LinkedNolaNovelIdLink = "linked_nola_novel_id_link",
  KnowHowViewDetail = "know_how_view_detail",
  UseNolaNovelButton = "use_nola_novel_button",
  TopOnboarding = "top_onboarding",
  TopOnboardingClose = "top_onboarding_close",
  TopOnboardingNovel = "top_onboarding_novel",
  TopOnboardingOutside = "top_onboarding_outside",
  EditorOnboardingMenu = "editor_onboarding_menu",
  EditorOnboardingNext = "editor_onboarding_next",
  EditorOnboardingClose = "editor_onboarding_close",
  EditorOnboardingOutside = "editor_onboarding_outside",
  EditorOnboardingBack = "editor_onboarding_back",
  TopOnboardingImport = "top_onboarding_import",
  TopOnboardingImportSkip = "top_onboarding_import_skip",
  TopOnboardingImportDone = "top_onboarding_import_done",
  TopOnboardingAgent = "top_onboarding_agent",
  TopOnboardingCreate = "top_onboarding_create",
  TopOnboardingNext = "top_onboarding_next",
  TopOnboardingCreateClose = "top_onboarding_create_close",
  TopOnboardingCreateOutside = "top_onboarding_create_outside",
  PostToNolaNovelFromCompany = "post_to_nola_novel_from_company",
  PostToNolaNovelFromCompanyA = "post_to_nola_novel_from_company_a",
  PostToNolaNovelFromCompanyB = "post_to_nola_novel_from_company_b",
  PenNameSavePopup = "pen_name_save_popup",
  PenNameSavePopupCompleteButton = "pen_name_save_popup_complete_button",
  PenNameSavePopupCancelButton = "pen_name_save_popup_cancel_button",
}

export enum NolaPageName {
  EventCompany = "event_company",
  Editor = "editor",
  EventTheme = "event_theme",
  NolaNovel = "nola_novel",
  NolaNovelNotification = "nola_novel_notification",
  Detail = "detail",
  KnowHow = "know_how",
}
